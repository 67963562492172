import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import Div from "@jumbo/shared/Div/Div";
import { CircularProgress } from "@mui/material";
import {
  createMessageTemplate,
  deleteMessage,
  deleteNewMessage,
  getAllMessagesList,
  getMyMessageList,
} from "backendServices/ApiCalls";
import { useNavigate } from "react-router-dom";
import { useModalState } from "app/hooks/use-modal-state";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useTranslation } from "react-i18next";
import { useJumboTheme } from "@jumbo/hooks";
import TabMenu from "app/pages/components/mui/TabMenu/TabMenu";
import { libraryButtonsConfig } from "../sections/LibraryTabData";
import {
  CustomPagination,
  Datatable,
} from "app/pages/components/mui/Datatable";
import { useGridApiRef } from "@mui/x-data-grid";
import TopHeader from "app/pages/components/mui/TopHeader/TopHeader";
import { MessageDialog } from "../message-dialog";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { UpdateMessageScreen } from "../message-dialog/steps/update-message-screen";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PreviewScreen } from "../message-dialog/steps/preview-screen";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";

const MessageList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [messageData, setMessageData] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();
  const [isEditPop, setEditPop] = useState(false);
  const [previewData, setPreviewData] = useState();
  const [selectedData, setSelectedData] = useState(null);
  const [duplicateData, setDuplicateData] = useState(null);
  const duplicateModal = useModalState();

  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const newMessageModal = useModalState();
  const [isCreateMessage, setIsCreateMessage] = useState(false);

  const { theme } = useJumboTheme();
  const [storedLocale, setStoredLocale] = React.useState(
    localStorage.getItem("selectedLocale")
  );
  const apiRef = useGridApiRef();
  const [selectedType, setSelectedType] = useState("");

  const FetchMessageData = () => {
    getAllMessagesList(
      {},
      (response) => {
        setMessageData(response.data);
        setLoading(false);
      },
      (error) => {}
    );
  };
  const deleteModal = useModalState();
  useEffect(() => {
    FetchMessageData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setStoredLocale(localStorage.getItem("selectedLocale"));
    }, 300);
  }, [theme]);

  function handleDelete() {
    deleteNewMessage(
      { messageId: deleteId },
      (response) => {
        setalertData({
          show: true,
          message: `${t("pages.title.Deleted Successfully")}`,
          variant: "success",
        });
        deleteModal.onClose();
        FetchMessageData();
      },
      (error) => {}
    );
  }

  const handledEdit = (response) => {
    setSelectedMessage(response);
    setIsCreateMessage(false);
  };

  const closeEdit = () => {
    setSelectedMessage(null);
    setEditPop(false);
  };

  const onSuccessClose = () => {
    FetchMessageData();

    setSelectedMessage(null);
    setEditPop(false);
  };

  useEffect(() => {
    if (previewData) {
      setIsCreateMessage(true);
    }
  }, [previewData]);

  useEffect(() => {
    if (selectedMessage) {
      setEditPop(true);
    }
  }, [selectedMessage]);

  const handleDuplicate = () => {
    const params = {
      name: `${duplicateData?.title} (Copy)`,
      variants: duplicateData?.variants?.map((variant) => variant?.name),
    };
    createMessageTemplate(
      params,
      () => {
        setalertData({
          show: true,
          message: "Duplicate created Successfully",
          variant: "success",
        });
        FetchMessageData();
        duplicateModal.onClose();
      },
      (error) => {
        console.log(error);
        duplicateModal.onClose();
      }
    );
  };

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const columns = [
    {
      field: "title",
      headerName: "Name",
      dataGeneratorUniquenessEnabled: true,
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              cursor: "pointer",
            }}
          >
            <Typography variant="body2">{params.row.title}</Typography>
          </Div>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: "100%",
              height: 36,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<VisibilityIcon />}
              onClick={() => handlePreview(params.row)}
            >
              Preview
            </Button>
            <Button
              color="success"
              sx={{
                height: 36,
                pl: 3,
                pr: 3,
                ml: 2,
                backgroundColor: "#D1EFF6",
                color: "#006C9C",
                border: "solid 1px #006C9C",
                "&:hover": {
                  backgroundColor: "#006C9C",
                  color: "#D1EFF6",
                  border: "solid 1px #D1EFF6",
                },
              }}
              variant="outlined"
              onClick={() => {
                duplicateModal.onOpen();
                setDuplicateData(params.row);
              }}
            >
              <FileCopyOutlinedIcon sx={{ mr: "5px", height: "20px" }} />

              <span>{t("pages.title.duplicate")}</span>
            </Button>

            <Button
              color="primary"
              onClick={() => handledEdit(params.row)}
              sx={{
                height: 36,
                ml: 2,
                backgroundColor: "#D1EDE5",
                color: "#007867",
                border: "solid 1px #007867",
                "&:hover": {
                  backgroundColor: "#007867",
                  color: "#D1EDE5",
                  border: "solid 1px #D1EDE5",
                },
              }}
              variant="outlined"
            >
              <EditCalendarIcon sx={{ mr: "5px", height: "20px" }} />
              <span>Edit</span>
            </Button>

            <Button
              color="error"
              onClick={() => {
                setDeleteId(params.row.id);
                deleteModal.onOpen();
              }}
              sx={{
                height: 36,
                ml: 2,
                pr: 3,
                backgroundColor: "#FAE0DB",
                color: "#FF0202",
                border: "solid 1px #FF0202",
                "&:hover": {
                  backgroundColor: "#FF0202",
                  color: "#FAE0DB",
                  border: "solid 1px #D1EFF6",
                },
              }}
              variant="outlined"
            >
              <DeleteOutlineOutlinedIcon sx={{ mr: "5px", height: "20px" }} />
              {t("pages.title.delete")}
            </Button>
          </Box>
        );
      },
    },
  ];

  const filterByType = (type) => {
    if (type === "") {
      return messageData;
    } else {
      return messageData.filter((message) =>
        message.MessageDataTypes.some((data) => data.type === type)
      );
    }
  };

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          px: 0.5,
          py: 1.5,
          "& .MuiInputBase-root": {
            "&:after": {
              border: "none",
            },
          },
          display: "flex",
        }}
      >
        <GridToolbarQuickFilter className="custom-search-class-nova" />

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
          <Button
            variant="contained"
            onClick={newMessageModal.onOpen}
            sx={{
              backgroundColor: "#22C55E",
              color: "white",
              "&:hover": {
                backgroundColor: "#22C55E",
                color: "white",
              },
            }}
            startIcon={
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.21098 8.51251L8.21098 11.0624C8.21116 11.3453 7.9814 11.5751 7.69849 11.5749C7.41548 11.5748 7.1861 11.3455 7.1861 11.0624L7.1861 8.51252L4.63619 8.51252L4.63614 8.51252C4.35304 8.51234 4.12363 8.28302 4.1236 7.99998L8.21098 8.51251ZM8.21098 8.51251L10.7609 8.51251C11.0442 8.51251 11.2737 8.28314 11.2733 7.99998C11.2733 7.717 11.0439 7.48765 10.7609 7.48765L8.21098 7.48765V4.93773C8.21098 4.65468 7.98156 4.42533 7.69844 4.42515H7.69839C7.41536 4.42515 7.18596 4.65455 7.18596 4.93758L7.18596 7.48749L4.63609 7.48749C4.3532 7.48731 4.12345 7.71704 4.1236 7.99994L8.21098 8.51251ZM12.7013 2.9973C9.93839 0.234358 5.45871 0.234358 2.69577 2.9973C-0.0673305 5.7604 -0.0671737 10.2399 2.69577 13.0029C5.45886 15.766 9.93823 15.766 12.7013 13.0029C15.4643 10.2399 15.4644 5.7604 12.7013 2.9973ZM3.41081 12.2878C1.05215 9.92917 1.06159 6.08105 3.42057 3.72207C5.77938 1.36326 9.61775 1.36326 11.9766 3.72207C14.3354 6.08087 14.3354 9.91926 11.9766 12.2781C9.61761 14.637 5.76946 14.6465 3.41081 12.2878Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="0.15"
                ></path>
              </svg>
            }
          >
            {t("pages.title.create_message")}
          </Button>
        </Box>
      </Box>
    );
  }
  const handlePreview = (response) => {
    setSelectedData(response);
    let variants = [];

    for (let i = 0; i < response?.variants?.length; i++) {
      variants.push(response?.variants?.[i]?.name);
    }

    setPreviewData(variants);
  };
  const closePreview = () => {
    setIsCreateMessage(false);
    setPreviewData(null);
  };

  return (
    <>
      <div className="library-tabs">
        <TopHeader
          title={t("pages.title.Your Messages")}
          url={t("pages.title.message_url")}
        />
        <TabMenu buttonsConfig={libraryButtonsConfig} />
      </div>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}{" "}
      <Typography sx={{ color: "#170F49" }} variant="h2"></Typography>
      <div className="nova-container">
        <div className="nova-box library-box-only library-messgae">
          <Datatable
            apiRef={apiRef}
            className="nova-table-ctm"
            rows={filterByType(selectedType)}
            columns={columns}
            slots={{
              toolbar: QuickSearchToolbar,
              pagination: CustomPagination,
            }}
          />
        </div>
      </div>
      <Dialog
        maxWidth="xs"
        open={deleteModal.isOpen}
        sx={{
          ".MuiDialog-container": {
            mt: "-80px",
          },
          "& .MuiDialog-paper": {
            width: "80%",
            marginTop: "174px",
            borderRadius: "12px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F4F1FA",
            color: "#2C73FF",
            p: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "20px",
              textAlign: "center",
              flexGrow: 1,
              color: "#2C73FF",
            }}
          >
            Delete Message
          </Typography>
        </Box>
        <DialogContent className="stage-popup-main" sx={{ padding: "0px" }}>
          <Box
            className="stage-popup-main-text"
            sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
              }}
            >
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.26444 16.0377H15.7356C15.9543 16.0377 16.1694 15.9809 16.3597 15.8729C16.5499 15.7649 16.7089 15.6093 16.8211 15.4215C16.9333 15.2336 16.9948 15.0199 16.9997 14.8012C17.0046 14.5824 16.9526 14.3662 16.8489 14.1735L10.1137 1.66514C9.63589 0.778287 8.36411 0.778287 7.8863 1.66514L1.15114 14.1735C1.04742 14.3662 0.995449 14.5824 1.00031 14.8012C1.00518 15.0199 1.0667 15.2336 1.17889 15.4215C1.29107 15.6093 1.45006 15.7649 1.64034 15.8729C1.83061 15.9809 2.04565 16.0377 2.26444 16.0377Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.77164 6.12383L8.99849 10.9454L9.22494 6.12581C9.22634 6.095 9.22144 6.06423 9.21054 6.03537C9.19965 6.00652 9.18298 5.98019 9.16156 5.958C9.14015 5.9358 9.11443 5.91821 9.08598 5.90629C9.05754 5.89437 9.02696 5.88838 8.99612 5.88868C8.96581 5.88898 8.93588 5.89534 8.90808 5.9074C8.88028 5.91946 8.85517 5.93696 8.83425 5.95888C8.81332 5.9808 8.797 6.00669 8.78625 6.03502C8.7755 6.06335 8.77053 6.09355 8.77164 6.12383Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.9994 14.1004C8.84307 14.1004 8.69025 14.054 8.56027 13.9672C8.43029 13.8803 8.32898 13.7569 8.26915 13.6124C8.20933 13.468 8.19367 13.3091 8.22417 13.1557C8.25467 13.0024 8.32995 12.8616 8.44049 12.751C8.55104 12.6405 8.69187 12.5652 8.8452 12.5347C8.99853 12.5042 9.15745 12.5199 9.30188 12.5797C9.44631 12.6395 9.56976 12.7408 9.65661 12.8708C9.74347 13.0008 9.78982 13.1536 9.78982 13.31C9.78982 13.5196 9.70655 13.7206 9.55831 13.8689C9.41008 14.0171 9.20904 14.1004 8.9994 14.1004Z"
                  fill="#FF0202"
                  fillOpacity="0.7"
                />
              </svg>
              <Typography sx={{ textAlign: "center" }}>
                {t("pages.title.delete_data")}
              </Typography>
            </Box>

            <Box
              className="stage-btn-group"
              sx={{
                backgroundColor: "#F4F1FA",
                m: "-20px",
                pb: "15px",
                pt: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                }}
              >
                <button
                  variant="contained"
                  color="error"
                  className="red-pop-btn"
                  onClick={handleDelete}
                >
                  {t("pages.title.delete")}
                </button>
                <button onClick={deleteModal.onClose} className="gray-pop-btn">
                  {t("pages.title.cancel")}
                </button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="xs"
        open={duplicateModal.isOpen}
        sx={{
          ".MuiDialog-container": {
            alignItems: "flex-start",
          },
          "& .MuiDialog-paper": { mt: 12, width: "80%", maxHeight: 435 },
        }}
      >
        <DialogContent>
          <Typography color="grey.600" mt={3} variant="body2">
            {t("pages.title.duplicate_data")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              duplicateModal.onClose();
              setDuplicateData(null);
            }}
          >
            <span>{t("pages.title.cancel")}</span>
          </Button>
          <Button onClick={handleDuplicate} variant="contained">
            <span>{t("pages.title.confirm")}</span>
          </Button>
        </DialogActions>
      </Dialog>
      <MessageDialog
        modalState={newMessageModal}
        refetch={FetchMessageData}
        onlyCreateMessage={true}
        messageData={messageData}
      />
      <Dialog
        open={isEditPop}
        fullWidth
        sx={{
          ".MuiPaper-root ": {
            maxWidth: "100%",
          },
        }}
      >
        <UpdateMessageScreen
          selectedMessage={selectedMessage}
          onSuccessClose={onSuccessClose}
          closeEdit={closeEdit}
          messageData={messageData}
        />
      </Dialog>
      <Dialog
        open={isCreateMessage}
        fullWidth
        sx={{
          ".MuiPaper-root ": {
            maxWidth: "100%",
          },
        }}
      >
        <PreviewScreen
          previewData={previewData}
          isUpdate={true}
          selectedData={selectedData}
          closePreview={closePreview}
          handledEdit={handledEdit}
        />
      </Dialog>
    </>
  );
};

export default MessageList;
