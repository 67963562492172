import React, { Suspense } from "react";
import PropTypes from "prop-types";

import { CssBaseline, IconButton } from "@mui/material";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import JumboLayoutFooter from "./JumboLayoutFooter";
import JumboLayoutSidebar from "./JumboLayoutSidebar";
import Div from "@jumbo/shared/Div";
import useJumboLayoutRoot from "@jumbo/hooks/useJumboLayoutRoot";
import useJumboLayoutContent from "@jumbo/hooks/useJumboLayoutContent";
import { useContext } from "react";
import { AppContext } from "app/AppContext";
import SidebarSkeleton from "app/layouts/shared/sidebars/Sidebar/SidebarSkeleton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const JumboLayout = (props) => {
  const { sidebarOptions, setSidebarOptions, setSideBarOpen, sideBarOpen } =
    useJumboLayoutSidebar();
  const { rootOptions } = useJumboLayoutRoot();
  const { contentOptions } = useJumboLayoutContent();
  const { checkLogin } = useContext(AppContext);
  const LoginStatus = checkLogin();

  const headerHeightProps = React.useMemo(() => {
    if (props?.headerSx?.height) {
      return { height: props?.headerSx?.height };
    }
    return {};
  }, [props?.headerSx]);

  return (
    <Div
      sx={{
        display: "flex",
        flex: 1,
        minWidth: 0,
        minHeight: "100%",
        flexDirection: "column",
        ...rootOptions?.sx,
      }}
      className="CmtLayout-root"
    >
      <CssBaseline />

      <Suspense
        fallback={
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              alignItems: "center",
              alignContent: "center",
              px: 3,
            }}
          >
            <SidebarSkeleton />
          </Div>
        }
      ></Suspense>

      <Div
        sx={{
          display: "flex",
          flex: 1,
          minWidth: 0,
          position: "relative",
        }}
        className="CmtLayout-wrapper"
      >
        {LoginStatus && (
          <JumboLayoutSidebar headerHeightProps={headerHeightProps}>
            {props.sidebar}
          </JumboLayoutSidebar>
        )}

        <Div
          sx={{
            display: "flex",
            minWidth: 0,
            flex: 1,
            flexDirection: "column",
            minHeight: "100%",
            transition: (theme) => theme.transitions.create(["margin-left"]),
          }}
          className="CmtLayout-main"
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flex: 1,
              flexDirection: "column",
              py: 3,
              px: { lg: 6, xs: 4 },
              ...(contentOptions?.sx ?? {}),
            }}
            className="CmtLayout-content"
          >
            {!sideBarOpen && LoginStatus && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ ml: 0, mr: -1.5 }}
                style={{
                  border: "1px solid #D8D8D8",
                  position: "absolute",
                  left: "60px",
                  zIndex: 9999,
                  background: "rgb(255, 255, 255)",
                }}
                onClick={() => {
                  setSidebarOptions({
                    open: !sidebarOptions?.open,
                  });
                  setSideBarOpen(!sideBarOpen);
                }}
              >
                <ArrowForwardIosIcon style={{ fontSize: "1rem" }} />
              </IconButton>
            )}

            {props.children}
          </Div>
          <JumboLayoutFooter>{props.footer}</JumboLayoutFooter>
        </Div>
      </Div>
    </Div>
  );
};

JumboLayout.propTypes = {
  header: PropTypes.node,
  headerSx: PropTypes.object,
  sidebar: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
};

export default JumboLayout;
