import React, { useEffect, useState } from "react";
import AffiliateLayout from "./common/AffiliateLayout";
import CustomerCard from "./common/CustomerCard";
import CustomerTable from "./common/CustomerTable";
import { Link, useSearchParams } from "react-router-dom";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import { BsFunnel, BsGrid, BsListUl } from "react-icons/bs";
import { dashboarddataApi } from "backendServices/ApiCalls";

export default function AllAffiliatedCustomers() {
	const [dashboardData, setdDashboardData] = useState(null);
	const [loader, setLoader] = useState(true);

	console.log('dashboardData ====>',dashboardData , "typeof(dashboardData)", typeof(dashboardData) )


  const [searchParams] = useSearchParams();
  let type =
    searchParams.get("type") !== ""
      ? searchParams.get("type").toLocaleLowerCase()
      : "grid";
  let buttonText = type === "grid" ? "list" : "grid";

	const GetDashboardData = () => {
		console.log('object')
    setLoader(true);
    dashboarddataApi(
      (response) => {
				// console.log('response in sadvbudjn=======>', response?.data?.dashboardData)
        setdDashboardData(response?.data?.dashboardData);
        setLoader(false);
      },
      (error) => {
        setLoader(false);
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    GetDashboardData();
  }, []);

  const customersInfo = [
    {
      name: "John Doe",
      email: "users@gmail.com",
      plan: "Pro",
      price: "$ 10,000.00",
      joiningDate: "20-10-2023",
      nextPayment: "20-10-2024",
      sponsoredBy: "Novalya",
      status: "Paid",
      image: "https://gravatar.com/avatar/HASH",
    },
    {
      name: "Kevin Morys",
      email: "users@gmail.com",
      plan: "Stater",
      price: "$ 10,000.00",
      joiningDate: "20-10-2023",
      nextPayment: "20-10-2024",
      sponsoredBy: "Novalya",
      status: "To Be Collected",
      image: "https://gravatar.com/avatar/HASH",
    },
    {
      name: "John Ponsard",
      email: "users@gmail.com",
      plan: "Unlimited",
      price: "$ 10,000.00",
      joiningDate: "20-10-2023",
      nextPayment: "20-10-2024",
      sponsoredBy: "Novalya",
      status: "Payment Due",
      image: "https://gravatar.com/avatar/HASH",
    },
    {
      name: "Audrey Knoderer",
      email: "users@gmail.com",
      plan: "Stater",
      price: "$ 10,000.00",
      joiningDate: "20-10-2023",
      nextPayment: "20-10-2024",
      sponsoredBy: "Novalya",
      status: "Cancelled",
      image: "https://gravatar.com/avatar/HASH",
    },
    {
      name: "John Doe",
      email: "users@gmail.com",
      plan: "Stater",
      price: "$ 10,000.00",
      joiningDate: "20-10-2023",
      nextPayment: "20-10-2024",
      sponsoredBy: "Novalya",
      status: "Paid",
      image: "https://gravatar.com/avatar/HASH",
    },
    {
      name: "Kevin Morys",
      email: "users@gmail.com",
      plan: "Stater",
      price: "$ 10,000.00",
      joiningDate: "20-10-2023",
      nextPayment: "20-10-2024",
      sponsoredBy: "Novalya",
      status: "To Be Collected",
      image: "https://gravatar.com/avatar/HASH",
    },
    {
      name: "John Ponsard",
      email: "users@gmail.com",
      plan: "Stater",
      price: "$ 10,000.00",
      joiningDate: "20-10-2023",
      nextPayment: "20-10-2024",
      sponsoredBy: "Novalya",
      status: "Payment Due",
      image: "https://gravatar.com/avatar/HASH",
    },
    {
      name: "Audrey Knoderer",
      email: "users@gmail.com",
      plan: "Stater",
      price: "$ 10,000.00",
      joiningDate: "20-10-2023",
      nextPayment: "20-10-2024",
      sponsoredBy: "Novalya",
      status: "Cancelled",
      image: "https://gravatar.com/avatar/HASH",
    },
  ];
  const handleChange = (event) => {
    console.log(event.target.value);
  };
  return (
    <>
      <AffiliateLayout>
        <div
          className="title-area"
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "30px",
          }}
        >
          <div className="title-area-left">
            <h2>All Customers</h2>
          </div>
          <div
            className="title-area-right"
            style={{
              gap: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControl sx={{ width: "150px" }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value="name-asc"
                label="Sort By"
                onChange={handleChange}
              >
                <MenuItem value="name-asc">Name (A-Z)</MenuItem>
                <MenuItem value="name-desc">Name (Z-A)</MenuItem>
              </Select>
            </FormControl>
            <Button
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                textTransform: "capitalize",
              }}
              variant="contained"
              color="primary"
            >
              <BsFunnel />
              <span>Filter</span>
            </Button>
            <Button
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                textTransform: "capitalize",
              }}
              as={Link}
              to={"/affiliate-dashboard/all-customers?type=" + buttonText}
              variant="contained"
              color="success"
            >
              {type === "grid" ? (
                <>
                  <BsListUl />
                  <span>List</span>
                </>
              ) : (
                <>
                  <BsGrid />
                  <span>Grid</span>
                </>
              )}
            </Button>
          </div>
        </div>
        {type === "grid" ? (
          <div
            className="data-grid-area"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: "10px",
              border: "none",
              margin: "8px 0px 12px",
            }}
          >
            {dashboardData?.referralNewUserData?.referralUserData?.map((data) => {
              return <CustomerCard userData={data} dashboardData={dashboardData} />;
            })}
          </div>
        ) : (
          <div className="data-table-area" style={{ margin: "8px 0px 12px" }}>
            <CustomerTable dashboardData={dashboardData} />
          </div>
        )}
      </AffiliateLayout>
    </>
  );
}
