export const Terms = [
            {
                "id": 121,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Basic-Novalya-USD-Monthly",
                "currency_code": "USD",
                "period_unit": "month",
                "family_id": "Novalya",
                "amount_1": "97",
                "amount_2": "97",
                "plan_type": "Novalya-all",
                "connections": 1,
                "limits": "Basic",
                "trial": "Yes",
                "status": 1
            },
            {
                "id": 123,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Business-Novalya-USD-Monthly",
                "currency_code": "USD",
                "period_unit": "month",
                "family_id": "Novalya",
                "amount_1": "197",
                "amount_2": "197",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Business",
                "trial": "Yes",
                "status": 1
            },
            {
                "id": 125,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Unlimited-Novalya-USD-Monthly",
                "currency_code": "USD",
                "period_unit": "month",
                "family_id": "Novalya",
                "amount_1": "297",
                "amount_2": "297",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Unlimited_new",
                "trial": "Yes",
                "status": 1
            },
            {
                "id": 127,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Basic-Novalya-USD-Every-3-months",
                "currency_code": "USD",
                "period_unit": "quater",
                "family_id": "Novalya",
                "amount_1": "231",
                "amount_2": "97",
                "plan_type": "Novalya-all",
                "connections": 1,
                "limits": "Basic",
                "trial": "Yes",
                "status": 1
            },
            {
                "id": 129,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Business-Novalya-USD-Every-3-months",
                "currency_code": "USD",
                "period_unit": "quater",
                "family_id": "Novalya",
                "amount_1": "471",
                "amount_2": "197",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Business",
                "trial": "Yes",
                "status": 1
            },
            {
                "id": 131,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Unlimited-Novalya-USD-Every-3-months",
                "currency_code": "USD",
                "period_unit": "quater",
                "family_id": "Novalya",
                "amount_1": "711",
                "amount_2": "297",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Unlimited_new",
                "trial": "Yes",
                "status": 1
            },
            {
                "id": 133,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Basic-Novalya-USD-Yearly",
                "currency_code": "USD",
                "period_unit": "year",
                "family_id": "Novalya",
                "amount_1": "756",
                "amount_2": "97",
                "plan_type": "Novalya-all",
                "connections": 1,
                "limits": "Basic",
                "trial": "Yes",
                "status": 1
            },
            {
                "id": 135,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Business-Novalya-USD-Yearly",
                "currency_code": "USD",
                "period_unit": "year",
                "family_id": "Novalya",
                "amount_1": "1536",
                "amount_2": "197",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Business",
                "trial": "Yes",
                "status": 1
            },
            {
                "id": 137,
                "subdomain": "app",
                "plan_name": null,
                "plan_id": "Unlimited-Novalya-USD-Yearly",
                "currency_code": "USD",
                "period_unit": "year",
                "family_id": "Novalya",
                "amount_1": "2316",
                "amount_2": "297",
                "plan_type": "Novalya-all",
                "connections": 2,
                "limits": "Unlimited_new",
                "trial": "Yes",
                "status": 1
            },

        
                    {
                        "id": 122,
                        "subdomain": "app",
                        "plan_name": null,
                        "plan_id": "Basic-Novalya-EUR-Monthly",
                        "currency_code": "EUR",
                        "period_unit": "month",
                        "family_id": "Novalya",
                        "amount_1": "97",
                        "amount_2": "97",
                        "plan_type": "Novalya-all",
                        "connections": 1,
                        "limits": "Basic",
                        "trial": "Yes",
                        "status": 1
                    },
                    {
                        "id": 124,
                        "subdomain": "app",
                        "plan_name": null,
                        "plan_id": "Business-Novalya-EUR-Monthly",
                        "currency_code": "EUR",
                        "period_unit": "month",
                        "family_id": "Novalya",
                        "amount_1": "197",
                        "amount_2": "197",
                        "plan_type": "Novalya-all",
                        "connections": 2,
                        "limits": "Business",
                        "trial": "Yes",
                        "status": 1
                    },
                    {
                        "id": 126,
                        "subdomain": "app",
                        "plan_name": null,
                        "plan_id": "Unlimited-Novalya-EUR-Monthly",
                        "currency_code": "EUR",
                        "period_unit": "month",
                        "family_id": "Novalya",
                        "amount_1": "297",
                        "amount_2": "297",
                        "plan_type": "Novalya-all",
                        "connections": 2,
                        "limits": "Unlimited_new",
                        "trial": "Yes",
                        "status": 1
                    },
                    {
                        "id": 128,
                        "subdomain": "app",
                        "plan_name": null,
                        "plan_id": "Basic-Novalya-EUR-Every-3-months",
                        "currency_code": "EUR",
                        "period_unit": "quater",
                        "family_id": "Novalya",
                        "amount_1": "231",
                        "amount_2": "97",
                        "plan_type": "Novalya-all",
                        "connections": 1,
                        "limits": "Basic",
                        "trial": "Yes",
                        "status": 1
                    },
                    {
                        "id": 130,
                        "subdomain": "app",
                        "plan_name": null,
                        "plan_id": "Business-Novalya-EUR-Every-3-months",
                        "currency_code": "EUR",
                        "period_unit": "quater",
                        "family_id": "Novalya",
                        "amount_1": "471",
                        "amount_2": "197",
                        "plan_type": "Novalya-all",
                        "connections": 2,
                        "limits": "Business",
                        "trial": "Yes",
                        "status": 1
                    },
                    {
                        "id": 132,
                        "subdomain": "app",
                        "plan_name": null,
                        "plan_id": "Unlimited-Novalya-EUR-Every-3-months",
                        "currency_code": "EUR",
                        "period_unit": "quater",
                        "family_id": "Novalya",
                        "amount_1": "711",
                        "amount_2": "297",
                        "plan_type": "Novalya-all",
                        "connections": 2,
                        "limits": "Unlimited_new",
                        "trial": "Yes",
                        "status": 1
                    },
                    {
                        "id": 134,
                        "subdomain": "app",
                        "plan_name": null,
                        "plan_id": "Basic-Novalya-EUR-Yearly",
                        "currency_code": "EUR",
                        "period_unit": "year",
                        "family_id": "Novalya",
                        "amount_1": "756",
                        "amount_2": "97",
                        "plan_type": "Novalya-all",
                        "connections": 1,
                        "limits": "Basic",
                        "trial": "Yes",
                        "status": 1
                    },
                    {
                        "id": 136,
                        "subdomain": "app",
                        "plan_name": null,
                        "plan_id": "Business-Novalya-EUR-Yearly",
                        "currency_code": "EUR",
                        "period_unit": "year",
                        "family_id": "Novalya",
                        "amount_1": "1536",
                        "amount_2": "197",
                        "plan_type": "Novalya-all",
                        "connections": 2,
                        "limits": "Business",
                        "trial": "Yes",
                        "status": 1
                    },
                    {
                        "id": 138,
                        "subdomain": "app",
                        "plan_name": null,
                        "plan_id": "Unlimited-Novalya-EUR-Yearly",
                        "currency_code": "EUR",
                        "period_unit": "year",
                        "family_id": "Novalya",
                        "amount_1": "2316",
                        "amount_2": "297",
                        "plan_type": "Novalya-all",
                        "connections": 2,
                        "limits": "Unlimited_new",
                        "trial": "Yes",
                        "status": 1
                    }
           
        ]
  
