import React, { useEffect, useState } from "react";
import AffiliateLayout from "./common/AffiliateLayout";
import {
  Button,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { BsFunnel, BsSearch } from "react-icons/bs";
import { getpoolreports } from "backendServices/ApiCalls";

export default function AffiliatePoolCommission() {
	const [loading, setLoading] = useState(true);
  const [poolData, setPoolData] = useState([]);

  const UnilevelData = () => {
    setLoading(true);
    getpoolreports(
      (response) => {
        setLoading(false);
        setPoolData(response?.data?.data);
      },
      (error) => {
        setLoading(false);
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    UnilevelData();
  }, []);

  const AffiliatePoolCommissionData = [
    {
      amount: "6.46 EUE",
      pool: "Danyee Root",
      achivedOnMonth: "Level 1 Bonus",
      paidOnMonth: "20-02-2024",
    },
    {
      amount: "6.46 EUE",
      pool: "Danyee Root",
      achivedOnMonth: "Level 1 Bonus",
      paidOnMonth: "20-02-2024",
    },
    {
      amount: "6.46 EUE",
      pool: "Danyee Root",
      achivedOnMonth: "Level 1 Bonus",
      paidOnMonth: "20-02-2024",
    },
    {
      amount: "6.46 EUE",
      pool: "Danyee Root",
      achivedOnMonth: "Level 1 Bonus",
      paidOnMonth: "20-02-2024",
    },
    {
      amount: "6.46 EUE",
      pool: "Danyee Root",
      achivedOnMonth: "Level 1 Bonus",
      paidOnMonth: "20-02-2024",
    },
    {
      amount: "6.46 EUE",
      pool: "Danyee Root",
      achivedOnMonth: "Level 1 Bonus",
      paidOnMonth: "20-02-2024",
    },
    {
      amount: "6.46 EUE",
      pool: "Danyee Root",
      achivedOnMonth: "Level 1 Bonus",
      paidOnMonth: "20-02-2024",
    },
    {
      amount: "6.46 EUE",
      pool: "Danyee Root",
      achivedOnMonth: "Level 1 Bonus",
      paidOnMonth: "20-02-2024",
    },
  ];

  const handleChange = (event) => {
    console.log(event.target.value);
  };

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		return date.toLocaleDateString('en-GB'); // 'en-GB' for dd/mm/yyyy format
	};

	const formatTime = (dateString) => {
		const date = new Date(dateString);
		const hours = String(date.getUTCHours()).padStart(2, '0');
		const minutes = String(date.getUTCMinutes()).padStart(2, '0');
		const seconds = String(date.getUTCSeconds()).padStart(2, '0');
		return `${hours}:${minutes}:${seconds}`;
	};

  return (
    <AffiliateLayout>
      <div className="title-area">
        <div className="title-area-left">
          <h2>Pool Commission</h2>
        </div>
      </div>
      <Card sx={{ padding: "20px" }}>
        <div
          className="title-area"
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "30px",
            marginBottom: "20px",
          }}
        >
          <div className="title-area-left">
            <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
              <InputLabel htmlFor="search">Search</InputLabel>
              <OutlinedInput
                id="search"
                type="text"
                startAdornment={
                  <InputAdornment position="center">
                    <IconButton edge="end">
                      <BsSearch />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search"
              />
            </FormControl>
          </div>
          <div
            className="title-area-right"
            style={{
              gap: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControl sx={{ width: "150px" }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value="name-asc"
                label="Sort By"
                onChange={handleChange}
              >
                <MenuItem value="name-asc">Name (A-Z)</MenuItem>
                <MenuItem value="name-desc">Name (Z-A)</MenuItem>
              </Select>
            </FormControl>
            <Button
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                textTransform: "capitalize",
              }}
              variant="contained"
              color="primary"
            >
              <BsFunnel />
              <span>Filter</span>
            </Button>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#E0E4E8" }}>
              <TableRow>
              <TableCell>#</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell align="left">Pool</TableCell>
                <TableCell align="center">Achieved on Month</TableCell>
                <TableCell align="center">Paid on Month</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {poolData.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Record Found
                    </TableCell>
                  </TableRow>
                )}
              {poolData.map((row, index) => (
                <TableRow
                  key={row.pool}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    backgroundColor: index % 2 === 0 ? "#F6F6F6" : "inherit", // alternate row color
                  }}
                >
                <TableCell
                      align="left"
                      sx={{
                        color: '#919EAB',
                        fontSize: "14px",
                        lineHeight: "18px",
                        fontFamily: "'DM Sans'",
                        fontWeight: "500",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      color: "#919EAB",
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontFamily: "'DM Sans'",
                      fontWeight: "500",
                    }}
                  >
                    {row.amount.toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      color: "#919EAB",
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontFamily: "'DM Sans'",
                      fontWeight: "500",
                    }}
                  >
                    {row.pool}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#919EAB",
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontFamily: "'DM Sans'",
                      fontWeight: "500",
                    }}
                  >
                    {row.createdMonth}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#919EAB",
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontFamily: "'DM Sans'",
                      fontWeight: "500",
                    }}
                  >
                    {row.approvedMonth}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </AffiliateLayout>
  );
}
