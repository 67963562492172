import { Dialog } from "@mui/material";
import { useState } from "react";
import { FirstScreen } from "./steps/first-Screen";
import { CreateMessageScreen } from "./steps/create-message-screen";
import { PreviewScreen } from "./steps/preview-screen";
import { MessageListScreen } from "./steps/message-list-screen";
import "./css/index.css";

export const stepCount = {
  firstScreen: 1,
  createScreen: 2,
  previewScreen: 3,
  listScreen: 4,
};

export function MessageDialog({
  setNewMessageId,
  modalState,
  refetch,
  onlyCreateMessage,
  messageData
}) {
  const [step, setStep] = useState(
    onlyCreateMessage ? stepCount.createScreen : stepCount.firstScreen
  );
  const [previewData, setPreviewData] = useState();
  const [messageId, setMessageId] = useState();
  const [isCreateMessage, setIsCreateMessage] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);

  const handleClose = () => {
    setStep(stepCount.firstScreen);
    modalState.setIsOpen(false);
  };
  return (
    <Dialog
      open={modalState.isOpen}
      fullWidth
      sx={{
        ".MuiPaper-root ": {
          maxWidth: "100%",
        },
      }}
    >
      {step === stepCount.firstScreen && (
        <FirstScreen
          setStep={setStep}
          handleClose={handleClose}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />
      )}
      {step === stepCount.createScreen && (
        <CreateMessageScreen
          setStep={setStep}
          handleClose={handleClose}
          setPreviewData={setPreviewData}
          setMessageId={setMessageId}
          refetch={refetch}
          setIsCreateMessage={setIsCreateMessage}
          previewData={previewData}
          isCreateMessage={isCreateMessage}
          setNewMessageId={setNewMessageId}
          messageId={messageId}
          messageData={messageData}
        />
      )}
      {step === stepCount.previewScreen && (
        <PreviewScreen
          setStep={setStep}
          handleClose={handleClose}
          previewData={previewData}
          setNewMessageId={setNewMessageId}
          messageId={messageId}
          isCreateMessage={isCreateMessage}
          setIsCreateMessage={setIsCreateMessage}
        />
      )}
      {step === stepCount.listScreen && (
        <MessageListScreen
          setStep={setStep}
          handleClose={handleClose}
          setPreviewData={setPreviewData}
          setNewMessageId={setNewMessageId}
          setMessageId={setMessageId}
          setIsCreateMessage={setIsCreateMessage}
        />
      )}
    </Dialog>
  );
}
