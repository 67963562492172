import TabMenu from 'app/pages/components/mui/TabMenu/TabMenu';
import React from 'react'
import { MdOutlineDashboard, MdCurrencyExchange } from "react-icons/md";
import { SlSettings } from "react-icons/sl";
import { BiDollarCircle } from "react-icons/bi";


const AllAffiliatedCustomersButtonsConfig = [
    {
        labelKey: "Dashboard",
        icon: <MdOutlineDashboard style={{ width: "24px", height: "24px" }} />,
        path: "/affiliate-dashboard/2",
        id: "affiliate-dashboard",
        socialAccount: null
    },
    {
        labelKey: "Affiliate Links",
        icon: <SlSettings style={{ width: "24px", height: "24px" }} />,
        path: "/affiliate-dashboard/links",
        id: "affiliate-dashboard--links",
        socialAccount: null
    },
    {
        labelKey: "Level Commission",
        icon: <MdCurrencyExchange style={{ width: "28px", height: "24px" }} />,
        path: "/affiliate-dashboard/level-commission",
        id: "affiliate-dashboard--level-commission",
        socialAccount: null
    },
    // {
    //     labelKey: "Pool Commission",
    //     icon: <MdCurrencyExchange style={{ width: "28px", height: "24px" }} />,
    //     path: "/affiliate-dashboard/pool-commission",
    //     id: "affiliate-dashboard--pool-commission",
    //     socialAccount: null
    // },
    {
        labelKey: "My Payments",
        icon: <BiDollarCircle style={{ width: "28px", height: "24px" }} />,
        path: "/affiliate-dashboard/my-payments",
        id: "affiliate-dashboard--my-payments",
        socialAccount: null
    },
    {
        labelKey: "Settings",
        icon: <SlSettings style={{ width: "24px", height: "24px" }} />,
        path: "/affiliate-dashboard/settings",
        id: "affiliate-dashboard--settings",
        socialAccount: null
    }
];

export default function AffiliateHeaderMenu() {
    return (
        <TabMenu buttonsConfig={AllAffiliatedCustomersButtonsConfig} columns={6} />
    )
}
