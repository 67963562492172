import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./tabmenu-new.css";
import { useNavigate } from "react-router-dom";

import { fetchSocialProfileFeature } from "backendServices/ApiCalls";
import SweetAlert from "../../mui/Alerts/SweetAlert";

const CustomButton = ({
  labelKey,
  icon,
  path,
  active,
  onClick,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const [fbUserId, setFbUserId] = useState();
  const [instaUserId, setInstaUserId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    fetchSocialProfileFeatures();
  }, []);

  const fetchSocialProfileFeatures = () => {
    ["Facebook", "Instagram"].forEach((media) => {
      let params = { media };
      fetchSocialProfileFeature(
        params,
        (response) => {
          let currentParams = response.config.params.media;
          if (currentParams === "Instagram") {
            setInstaUserId(response.data.data.insta_user_id);
          } else if (currentParams === "Facebook") {
            setFbUserId(response.data.data.fb_user_id);
          }
        },
        (error) => {}
      );
    });
  };

  const handleTabChange = (path) => {
    if (isDisabled) {
      return false;
    }
    navigate(path);
  };

  return (
    <div onClick={() => handleTabChange(path)}>
      <button
        className={`tabmenu-btn 12 ${
          isDisabled ? "disabled-btn" : active ? "tab-menu-active" : ""
        }`}
        userId={
          t(labelKey) === "Prospect on Facebook"
            ? fbUserId
            : t(labelKey) === "Prospect on Instagram"
            ? instaUserId
            : ""
        }
        prospectionTab={
          t(labelKey) === "Prospect on Facebook"
            ? "facebook"
            : t(labelKey) === "Prospect on Instagram"
            ? "instagram"
            : ""
        }
        onClick={onClick}
      >
        <span>{icon}</span> <span>{t(`${labelKey}`)}</span>
      </button>
    </div>
  );
};

const TabMenu = ({ buttonsConfig }) => {
  const [activeButton, setActiveButton] = useState(null);
  const [socialProfiles, setSocialProfiles] = useState({
    Facebook: true,
    Instagram: true,
  });

  const [alertData, setAlertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  useEffect(() => {
    const path = window.location.pathname;
    setActiveButton(path.substring(1));
  }, []);

  const handleButtonClick = (id) => {
    setActiveButton(id);
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        gap: 10,
        border: "none",
        margin: "8px 0 12px",
      }}
    >
      {alertData.show ? (
        <SweetAlert alertData={alertData} setalertData={setAlertData} />
      ) : (
        ""
      )}
      {buttonsConfig.map(({ labelKey, icon, path, id, socialAccount = "" }) => (
        <CustomButton
          key={id}
          labelKey={labelKey}
          icon={icon}
          path={path}
          active={activeButton === id}
          onClick={() =>
            socialAccount && !socialProfiles[socialAccount]
              ? setAlertData({
                  show: true,
                  message: `Please connect the  ${socialProfiles[socialAccount]} feature to continue.`,
                  variant: "error",
                })
              : handleButtonClick(id)
          }
          isDisabled={false}
        />
      ))}
    </div>
  );
};

export default TabMenu;
