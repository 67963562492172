import React, { useEffect, useState } from "react";
import AffiliateLayout from "./common/AffiliateLayout";
import {
  Button,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { BsFunnel, BsSearch } from "react-icons/bs";
import { PayoutReportDataApi } from "backendServices/ApiCalls";

export default function AffiliateMyPayments() {
  const [loading, setLoading] = useState(true);
  const [obj, setObj] = useState(null);
  const [payoutreportdata, setPayoutReportData] = useState([]);


  const InvestmentData = () => {
    setLoading(true);
    PayoutReportDataApi(
      (response) => {
        setPayoutReportData(response?.data?.data);
        setObj(response?.data?.obj);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    InvestmentData();
  }, []);

  const color = {
    Paid: "#22C55E",
    Pending: "#FF7A00",
  };
  const AffiliateMyPaymentsData = [
    {
      period: "6.46 EUE",
      withdrwalAmount: "€3450.77",
      finalAmount: "€3450.77",
      fees: "€5 ",
      status: "Paid",
      year: "2024",
    },
    {
      period: "6.46 EUE",
      withdrwalAmount: "€3450.77",
      finalAmount: "€3450.77",
      fees: "€5 ",
      status: "Pending",
      year: "2024",
    },
    {
      period: "6.46 EUE",
      withdrwalAmount: "€3450.77",
      finalAmount: "€3450.77",
      fees: "€5 ",
      status: "Pending",
      year: "2024",
    },
    {
      period: "6.46 EUE",
      withdrwalAmount: "€3450.77",
      finalAmount: "€3450.77",
      fees: "€5 ",
      status: "Paid",
      year: "2024",
    },
    {
      period: "6.46 EUE",
      withdrwalAmount: "€3450.77",
      finalAmount: "€3450.77",
      fees: "€5 ",
      status: "Paid",
      year: "2024",
    },
    {
      period: "6.46 EUE",
      withdrwalAmount: "€3450.77",
      finalAmount: "€3450.77",
      fees: "€5 ",
      status: "Pending",
      year: "2024",
    },
    {
      period: "6.46 EUE",
      withdrwalAmount: "€3450.77",
      finalAmount: "€3450.77",
      fees: "€5 ",
      status: "Paid",
      year: "2024",
    },
    {
      period: "6.46 EUE",
      withdrwalAmount: "€3450.77",
      finalAmount: "€3450.77",
      fees: "€5 ",
      status: "Paid",
      year: "2024",
    },
  ];

  const handleChange = (event) => {
    console.log(event.target.value);
  };

  const formatDate = (dateInput) => {
    const date = new Date(dateInput);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const getYear = (dateInput) => {
    const date = new Date(dateInput);
    return date.getFullYear();
  };

  return (
    <AffiliateLayout>
      <div className="title-area">
        <div className="title-area-left">
          <h2>My Payments</h2>
        </div>
      </div>
      <Card sx={{ padding: "20px" }}>
        <div
          className="title-area"
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "30px",
            marginBottom: "20px",
          }}
        >
          <div className="title-area-left">
            <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
              <InputLabel htmlFor="search">Search</InputLabel>
              <OutlinedInput
                id="search"
                type="text"
                startAdornment={
                  <InputAdornment position="center">
                    <IconButton edge="end">
                      <BsSearch />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search"
              />
            </FormControl>
          </div>
          <div
            className="title-area-right"
            style={{
              gap: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControl sx={{ width: "150px" }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value="name-asc"
                label="Sort By"
                onChange={handleChange}
              >
                <MenuItem value="name-asc">Name (A-Z)</MenuItem>
                <MenuItem value="name-desc">Name (Z-A)</MenuItem>
              </Select>
            </FormControl>
            <Button
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                textTransform: "capitalize",
              }}
              variant="contained"
              color="primary"
            >
              <BsFunnel />
              <span>Filter</span>
            </Button>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#E0E4E8" }}>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Period</TableCell>
                <TableCell align="center">Fee</TableCell>
                <TableCell align="center">Withdrwal amount</TableCell>
                <TableCell align="center">Final amount</TableCell>
                <TableCell align="center">Status</TableCell>
                {/* <TableCell align="center">Year</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {payoutreportdata.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    backgroundColor: index % 2 === 0 ? "#F6F6F6" : "inherit", // alternate row color
                  }}
                >
                <TableCell
                      align="left"
                      sx={{
                        color: '#919EAB',
                        fontSize: "14px",
                        lineHeight: "18px",
                        fontFamily: "'DM Sans'",
                        fontWeight: "500",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      color: "#919EAB",
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontFamily: "'DM Sans'",
                      fontWeight: "500",
                    }}
                  >
                    {formatDate(row.approvedat)}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#919EAB",
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontFamily: "'DM Sans'",
                      fontWeight: "500",
                    }}
                  >
                    {row.payout_fee + " " + row.currency}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#919EAB",
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontFamily: "'DM Sans'",
                      fontWeight: "500",
                    }}
                  >
                    {row.amount + " " + row.currency}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#919EAB",
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontFamily: "'DM Sans'",
                      fontWeight: "500",
                    }}
                  >
                    {row.final_amount + " " + row.currency}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#919EAB",
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontFamily: "'DM Sans'",
                      fontWeight: "500",
                    }}
                  >
                    <span
                      style={{
                        background: color[row.status],
                        padding: "3px 11px",
                        borderRadius: "6px",
                        color: "#919EAB",
                        display: "inline-flex",
                        width: "140px",
                        justifyContent: "center",
                      }}
                    >
                      {'Approved'}
                    </span>
                  </TableCell>
                  {/* <TableCell
                    align="center"
                    sx={{
                      color: "#919EAB",
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontFamily: "'DM Sans'",
                      fontWeight: "500",
                    }}
                  >
                    {getYear(row.approvedat)}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </AffiliateLayout>
  );
}
