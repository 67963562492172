import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import React, { useContext } from "react";

export default function CustomerCard(props) {
  const { loginUserData } = useContext(CustomProvider);
  const color = {
    Paid: "#22C55E",
    "To Be Collected": "#FFC804",
    "Payment Due": "#FF7A00",
    Cancelled: "#FF0000",
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const formatDate1 = (dateInput) => {
    let date;
    date = new Date(dateInput); // Handle ISO 8601 format
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        avatar={
          <Avatar
            alt="Avatar Image"
            src={
              props?.dashboardData?.referralNewUserData?.pictureUrl +
              props?.userData?.picture
            }
            sx={{ width: 44, height: 44 }}
          />
        }
        title={
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "18.8px",
              color: "#170F49",
            }}
          >
            {props?.userData?.firstname + " " + props?.userData?.lastname}
          </Typography>
        }
      />
      <Divider variant="middle" />
      <CardContent>
        <table>
          <tbody>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  color: "#170F49",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "16.45px",
                  padding: "8px 10px 8px 0px",
                }}
              >
                Email
              </th>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "16.45px",
                  color: "#5E3BB7",
                }}
              >
                {props?.userData?.email}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  color: "#170F49",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "16.45px",
                  padding: "8px 10px 8px 0px",
                }}
              >
                Plan
              </th>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "16.45px",
                  color: "#5E3BB7",
                }}
              >
                {props?.userData?.planid}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  color: "#170F49",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "16.45px",
                  padding: "8px 10px 8px 0px",
                }}
              >
                Price
              </th>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "16.45px",
                  color: "#5E3BB7",
                }}
              >
                {props?.userData?.paid_amount + " " + props?.userData?.currency}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  color: "#170F49",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "16.45px",
                  padding: "8px 10px 8px 0px",
                }}
              >
                Joining Date
              </th>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "16.45px",
                  color: "#5E3BB7",
                }}
              >
                {props?.userData?.createdat && formatDate1(props?.userData?.createdat)}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  color: "#170F49",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "16.45px",
                  padding: "8px 10px 8px 0px",
                }}
              >
                Next Payment
              </th>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "16.45px",
                  color: "#5E3BB7",
                }}
              >
                {props?.userData?.nextBillingAt && formatDate(props?.userData?.nextBillingAt)}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  color: "#170F49",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "16.45px",
                  padding: "8px 10px 8px 0px",
                }}
              >
                Sponsored by{" "}
              </th>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "16.45px",
                  color: "#5E3BB7",
                }}
              >
                {loginUserData?.username}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  color: "#170F49",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "16.45px",
                  padding: "8px 10px 8px 0px",
                }}
              >
                Status
              </th>
              <td
                style={{
                  textAlign: "left",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "16.45px",
                  color: "#5E3BB7",
                }}
              >
                {props?.userData?.subscription_status}
              </td>
            </tr>

          </tbody>
        </table>
      </CardContent>
    </Card>
  );
}
