import { useEffect, useMemo, useState } from "react";
import { stepCount } from "..";
import agtemplateicons from "../images/ag-template-icons.svg";
import { getMyMessageList } from "backendServices/ApiCalls";
import { Tooltip } from "@mui/material";
import LocalizationOptions from "app/shared/JumboCustomizer/components/LocalizationOptions";
import { useTranslation } from "react-i18next";

export function MessageListScreen({
  setStep,
  handleClose,
  setPreviewData,
  setNewMessageId,
  setMessageId,
  setIsCreateMessage,
}) {
  const [templateList, setTemplateList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();

  const FetchData = () => {
    getMyMessageList(
      {},
      (response) => {
        console.log(response);
        setTemplateList(response.data);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    FetchData();
  }, []);

  const filteredTemplates = useMemo(() => {
    return templateList?.messages?.filter((template) =>
      template.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [templateList, searchTerm]); // Recompute only when templateList or searchTerm changes

  return (
    <div class="ag-msg-popup">
      <div class="ag-msg-popup-inner ag-template-steps">
        <div class="ag-msg-popup-content">
          <div class="ag-template-mainWrap">
            <div class="ag-template-mainInner">
              <div class="ag-select-temp-header">
                <div class="ag-header-back-btn">
                  <div class="ag-msg-back-btn">
                    <button
                      class="ag-back-btn"
                      onClick={() => setStep(stepCount.firstScreen)}
                    >
                      <span>
                        <svg
                          width="5"
                          height="9"
                          viewBox="0 0 5 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 1L0.764706 4.23529L4 7.47059"
                            stroke="#898989"
                            stroke-width="1.25"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>{" "}
                      {t("pages.title.Back")}
                    </button>
                  </div>
                  <span class="ag-main-title">
                    {" "}
                    {t("pages.title.Select a Template")}
                  </span>
                </div>
                <div class="crm-right-search">
                  <form class="serach-form">
                    <input
                      type="text"
                      name=""
                      placeholder="Search ...."
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <p class="search-btn">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2083_436)">
                          <path
                            d="M10.0833 1.83301C14.6373 1.83301 18.3333 5.52901 18.3333 10.083C18.3333 14.637 14.6373 18.333 10.0833 18.333C5.52933 18.333 1.83333 14.637 1.83333 10.083C1.83333 5.52901 5.52933 1.83301 10.0833 1.83301ZM10.0833 16.4997C13.6281 16.4997 16.5 13.6278 16.5 10.083C16.5 6.53734 13.6281 3.66634 10.0833 3.66634C6.53766 3.66634 3.66666 6.53734 3.66666 10.083C3.66666 13.6278 6.53766 16.4997 10.0833 16.4997ZM17.8612 16.5648L20.4545 19.1571L19.1574 20.4542L16.5651 17.8609L17.8612 16.5648Z"
                            fill="#7551D2"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_2083_436">
                            <rect width="22" height="22" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </p>
                  </form>
                  <LocalizationOptions />
                </div>
              </div>
              <div class="ag-selectTemplate ag-scroll">
                <div class="ag-selectLeft ctm-scroll">
                  <ul class="ag-selectNav"></ul>
                  <ul class="ag-selectNav">
                    <li className="active">
                      <div>
                        <span>My Messages</span>
                        <svg
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.16388 6.71078C7.0902 6.77944 7.03109 6.86224 6.9901 6.95424C6.94911 7.04624 6.92707 7.14556 6.92529 7.24626C6.92351 7.34696 6.94204 7.44699 6.97976 7.54038C7.01748 7.63377 7.07363 7.7186 7.14484 7.78982C7.21606 7.86104 7.3009 7.91718 7.39428 7.95491C7.48767 7.99263 7.5877 8.01115 7.68841 8.00937C7.78911 8.0076 7.88842 7.98556 7.98042 7.94456C8.07242 7.90357 8.15522 7.84447 8.22388 7.77078L11.4749 4.52078L12.0049 3.99078L11.4749 3.46078L8.22488 0.210782C8.0835 0.0740957 7.89408 -0.00159032 7.69743 2.5151e-05C7.50078 0.00164062 7.31263 0.0804287 7.17351 0.21942C7.03439 0.358411 6.95542 0.546484 6.95362 0.743131C6.95182 0.939779 7.02733 1.12927 7.16388 1.27078L9.13388 3.24078L0.754883 3.24078C0.55597 3.24078 0.365206 3.3198 0.224553 3.46045C0.0839003 3.6011 0.00488265 3.79187 0.00488264 3.99078C0.00488263 4.18969 0.0839003 4.38046 0.224553 4.52111C0.365206 4.66176 0.55597 4.74078 0.754883 4.74078L9.13388 4.74078L7.16388 6.71078Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </div>
                    </li>
                    <li>
                      <Tooltip title="Coming Soon" placement="right">
                        <div style={{ opacity: 0.5 }}>
                          <span
                            style={{
                              display: "flex",
                              gap: "4px",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            My Favorites{" "}
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 12C5.81473 11.9997 5.63382 11.9391 5.4808 11.8259C3.2139 10.1587 2.23232 9.01563 1.6909 8.30094C0.537119 6.7775 -0.0152558 5.21344 0.000320296 3.51969C0.0184924 1.57875 1.45582 0 3.20438 0C4.47585 0 5.35648 0.775938 5.86933 1.42219C5.88558 1.44245 5.90565 1.4587 5.9282 1.46984C5.95074 1.48098 5.97522 1.48674 6 1.48674C6.02478 1.48674 6.04926 1.48098 6.07181 1.46984C6.09435 1.4587 6.11442 1.44245 6.13067 1.42219C6.64352 0.775313 7.52415 0 8.79562 0C10.5442 0 11.9815 1.57875 11.9997 3.52C12.0153 5.21406 11.4623 6.77813 10.3091 8.30125C9.76768 9.01594 8.7861 10.1591 6.5192 11.8263C6.36615 11.9393 6.18524 11.9998 6 12Z"
                                fill="#ff0000"
                              ></path>
                            </svg>
                          </span>
                          <svg
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.16388 6.71078C7.0902 6.77944 7.03109 6.86224 6.9901 6.95424C6.94911 7.04624 6.92707 7.14556 6.92529 7.24626C6.92351 7.34696 6.94204 7.44699 6.97976 7.54038C7.01748 7.63377 7.07363 7.7186 7.14484 7.78982C7.21606 7.86104 7.3009 7.91718 7.39428 7.95491C7.48767 7.99263 7.5877 8.01115 7.68841 8.00937C7.78911 8.0076 7.88842 7.98556 7.98042 7.94456C8.07242 7.90357 8.15522 7.84447 8.22388 7.77078L11.4749 4.52078L12.0049 3.99078L11.4749 3.46078L8.22488 0.210782C8.0835 0.0740957 7.89408 -0.00159032 7.69743 2.5151e-05C7.50078 0.00164062 7.31263 0.0804287 7.17351 0.21942C7.03439 0.358411 6.95542 0.546484 6.95362 0.743131C6.95182 0.939779 7.02733 1.12927 7.16388 1.27078L9.13388 3.24078L0.754883 3.24078C0.55597 3.24078 0.365206 3.3198 0.224553 3.46045C0.0839003 3.6011 0.00488265 3.79187 0.00488264 3.99078C0.00488263 4.18969 0.0839003 4.38046 0.224553 4.52111C0.365206 4.66176 0.55597 4.74078 0.754883 4.74078L9.13388 4.74078L7.16388 6.71078Z"
                              fill="#A4A4A4"
                            />
                          </svg>
                        </div>
                      </Tooltip>
                    </li>
                    {/* <li>
                      <div>
                        <span>Share With me</span>
                        <svg
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.16388 6.71078C7.0902 6.77944 7.03109 6.86224 6.9901 6.95424C6.94911 7.04624 6.92707 7.14556 6.92529 7.24626C6.92351 7.34696 6.94204 7.44699 6.97976 7.54038C7.01748 7.63377 7.07363 7.7186 7.14484 7.78982C7.21606 7.86104 7.3009 7.91718 7.39428 7.95491C7.48767 7.99263 7.5877 8.01115 7.68841 8.00937C7.78911 8.0076 7.88842 7.98556 7.98042 7.94456C8.07242 7.90357 8.15522 7.84447 8.22388 7.77078L11.4749 4.52078L12.0049 3.99078L11.4749 3.46078L8.22488 0.210782C8.0835 0.0740957 7.89408 -0.00159032 7.69743 2.5151e-05C7.50078 0.00164062 7.31263 0.0804287 7.17351 0.21942C7.03439 0.358411 6.95542 0.546484 6.95362 0.743131C6.95182 0.939779 7.02733 1.12927 7.16388 1.27078L9.13388 3.24078L0.754883 3.24078C0.55597 3.24078 0.365206 3.3198 0.224553 3.46045C0.0839003 3.6011 0.00488265 3.79187 0.00488264 3.99078C0.00488263 4.18969 0.0839003 4.38046 0.224553 4.52111C0.365206 4.66176 0.55597 4.74078 0.754883 4.74078L9.13388 4.74078L7.16388 6.71078Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </div>
                    </li> */}
                  </ul>
                </div>
                <div class="ag-selectRight ag-scroll">
                  <div class="ag-selectWraper">
                    {filteredTemplates?.map((item, index) => {
                      return (
                        <div class="ag-selectItems">
                          <button class="ag-favorite">
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 12C5.81473 11.9997 5.63382 11.9391 5.4808 11.8259C3.2139 10.1587 2.23232 9.01563 1.6909 8.30094C0.537119 6.7775 -0.0152558 5.21344 0.000320296 3.51969C0.0184924 1.57875 1.45582 0 3.20438 0C4.47585 0 5.35648 0.775938 5.86933 1.42219C5.88558 1.44245 5.90565 1.4587 5.9282 1.46984C5.95074 1.48098 5.97522 1.48674 6 1.48674C6.02478 1.48674 6.04926 1.48098 6.07181 1.46984C6.09435 1.4587 6.11442 1.44245 6.13067 1.42219C6.64352 0.775313 7.52415 0 8.79562 0C10.5442 0 11.9815 1.57875 11.9997 3.52C12.0153 5.21406 11.4623 6.77813 10.3091 8.30125C9.76768 9.01594 8.7861 10.1591 6.5192 11.8263C6.36615 11.9393 6.18524 11.9998 6 12Z"
                                fill="#C5C5C5"
                              />
                            </svg>
                          </button>
                          <span class="ag-selestImg">
                            <img src={agtemplateicons} alt="" />
                          </span>
                          <span class="ag-selectTitle">{item.title} </span>
                          <div class="ag-selectBtnWrap">
                            <button
                              class="ag-BorderBtn"
                              onClick={() => {
                                const variant = item.variants.map(
                                  (variant) => variant.name
                                );
                                setIsCreateMessage(false);
                                setPreviewData(variant);
                                setMessageId(item.id);
                                setStep(stepCount.previewScreen);
                              }}
                            >
                              {t("pages.title.Preview")}
                            </button>
                            <button
                              class="ag-FilBtn"
                              onClick={() => {
                                setNewMessageId(item.id);
                                handleClose();
                              }}
                            >
                              {t("pages.title.Select")}
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
