import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { Checkbox, FormControlLabel } from '@mui/material';
import { updateaffiliatecode } from 'backendServices/ApiCalls';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';

const useStyles = makeStyles((theme) => ({
    modal: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    dialogPaper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
    },
    header: {
        padding: theme.spacing(2),
        backgroundColor: '#f5f5f5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& h2': {
            marginBottom: 0,
        },
    },
    footer: {
        padding: theme.spacing(2),
        backgroundColor: '#f5f5f5',
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
    },
    label: {
        marginBottom: theme.spacing(1),
    },
    inputField: {
        width: '200px',
    },
    termsContainer: {
        display: 'flex',
        alignItems: 'center', // Align checkbox and text vertically
        marginBottom: theme.spacing(2), // Add some margin for spacing
    },
}));

export default function AffiliateLinkUpdateModal(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);
    const [myAffiliateCode, setMyAffiliateCode] = useState(props.data.randomcode)
    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: "",
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setMyAffiliateCode(props.data.randomcode)
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const updateMyAffiliateCode = (event) => {
        let inputValue = event.target.value
        inputValue = inputValue.toUpperCase();
        inputValue = inputValue.replace(/[^A-Z0-9]/g, "");
        setMyAffiliateCode(inputValue)
    }

    const handleUpdateMyAffiliateCode = (event) => {
        let myNewAffiliateCode = myAffiliateCode

        let params = {
            affiliatecode: myNewAffiliateCode,
        };

        updateaffiliatecode(
            params,
            (response) => {
                if (response?.data?.status === "success") {
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status,
                    });
                    props.updateLoginInfo(myNewAffiliateCode)
                    handleClose();
                } else if (response?.data?.status === "error") {
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status,
                    });
                } else {
                    setalertData({
                        show: true,
                        message: "Something went wrong, Please try again later.",
                        variant: "error",
                    });
                }
            },
            (error) => {
                console.log("Error updating affiliate code:", error?.response?.data);
            }
        );
    }

    return (
        <>
            <Button variant="contained" sx={{ padding: "11px 22px 11px 22px", background: "#5E3BB7", color: "#fff", borderRadius: "8px", fontSize: "18px", fontWeight: 500, lineHeight: "23.44px", letterSpacing: "0.34px", fontFamily: "'DM Sans'" }} onClick={handleClickOpen}>
                Update Affiliate Code
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth="sm"
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle className={classes.header} closeIcon={<IconButton onClick={handleClose} sx={{ textAlign: "center" }}>
                    <CloseIcon />
                </IconButton>}>
                    Update Affiliate Link
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        {alertData.show && ( <SweetAlert alertData={alertData} setalertData={setalertData} /> )}
                        <div className={classes.inputContainer}>
                            <label htmlFor="nameInput" className={classes.label}>Current Affiliate Code</label>
                            <TextField
                                id="nameInput"
                                label=""
                                variant="outlined"
                                className={classes.inputField}
                                value={props.data.randomcode}
                                readOnly
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className={classes.inputContainer}>
                            <label htmlFor="nameInput" className={classes.label}>New Affiliate Code</label>
                            <TextField
                                id="nameInput"
                                label=""
                                variant="outlined"
                                className={classes.inputField}
                                value={myAffiliateCode}
                                onChange={updateMyAffiliateCode}
                                style={{ width: '100%' }}
                                inputProps={{ maxLength: 10 }}
                            />
                        </div>
                        <div className={classes.termsContainer}>
                            <FormControlLabel
                                control={<Checkbox checked={checked} onChange={handleChange} />}
                                label=" I understand that all my previous links will be disabled and i want to update my affiliate links"
                                labelPlacement="end"
                            />
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.footer}>
                    <Button color='success' disabled={!checked || myAffiliateCode.trim().length === 0} variant="contained" autoFocus onClick={handleUpdateMyAffiliateCode}>
                        Submit
                    </Button>
                    <Button variant="outlined" onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
