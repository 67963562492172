import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { parse } from "date-fns";

export function SecondStep({ onChange, groupData, media }) {
  const { t } = useTranslation();
  const [norequest, setRequestCount] = useState(groupData.norequest ?? "5");
  const [interval, setIntervalCount] = useState(!groupData?.interval || groupData?.interval === "30-60" ?  "1-3":groupData?.interval);
  const [search_index, setSearchIndex] = useState(groupData.search_index ?? 1);
  const [stratagy, setStratagy] = useState((groupData?.stratagy === 0) || (groupData?.stratagy === 1) ? groupData?.stratagy:0 );
  const [launch_from, setLaunchFrom] = useState(
    groupData.search_index === 1 ? "no" : t("pages.title.custom")
  );
  const [customRequest, setCustomRequest] = useState( (groupData.custom === true  || parseInt(groupData.custom))? true : false);
  const [customRequestCount, setCustomRequestCount] = useState(
    parseInt(groupData.norequest)?groupData.norequest:"1"
  );

  console.log(groupData,"groupData")
  let intervalOption =
    media === "Instagram"
      ? [
          {
            value: "2-4",
            text: `<span>${t("pages.title.fast")}</span> ${t(
              "2 to 4 minutes"
            )}`,
          },
          {
            value: "4-6",
            text: `<span>${t("pages.title.medium")}</span> ${t(
              "4 to 6 minutes"
            )}`,
          },
          {
            value: "6-10",
            text: `<span>${t("pages.title.slow")}</span>${t(
              "6 to 10 minutes"
            )}`,
          },
          {
            value: "10-15",
            text: `<span>${t("pages.title.very_slow")}</span> ${t(
              "10 to 15 minutes"
            )}`,
          },
        ]
      : [
          // {
          //   value: "30-60",
          //   text: `<span>${t("pages.title.fast")}</span> ${t(
          //     "pages.title.30_to_60_second"
          //   )}`,
          // },
          {
            value: "1-3",
            text: `<span>${t("pages.title.medium")}</span> ${t(
              "pages.title.1_to_3_minutes"
            )}`,
          },
          {
            value: "3-5",
            text: `<span>${t("pages.title.slow")}</span>${t(
              "pages.title.3_to_5_minutes"
            )}`,
          },
          {
            value: "5-10",
            text: `<span>${t("pages.title.very_slow")}</span> ${t(
              "pages.title.5_to_10_minutes"
            )}`,
          },
        ];

  const filterIntervalOptions = (count, media) => {
    let filteredOptions = [...intervalOption];

    if (media === "Instagram") {
      if (count > 20 && count < 50) {
        filteredOptions = filteredOptions.filter(
          (item) => item.value !== "2-4"
        );
      } else if (count >= 40 && count < 50) {
        filteredOptions = filteredOptions.filter(
          (item) => item.value !== "2-4"
        );
      } else if (count >= 50) {
        filteredOptions = filteredOptions.filter(
          (item) => item.value !== "2-4"
        );
      }
    } else {
      if (count > 20 && count < 50) {
        filteredOptions = filteredOptions.filter(
          (item) => item.value !== "1-3"
        );

      } else if (count >= 40 && count < 50) {
        filteredOptions = filteredOptions.filter(
          (item) => item.value !== "1-3"
        );
      

      } else if (count >= 50) {
        filteredOptions = filteredOptions.filter(
          (item) => item.value !== "1-3"
        );
       

      }

      
    }

    return filteredOptions;
  };

  const [intervalOptions, setIntervalOptions] = useState(
    filterIntervalOptions(parseInt(norequest))
  );

  let searchOptions = [
    { value: "no", text: t("pages.title.from_start") },
    { value: t("pages.title.custom"), text: t("pages.title.custom") },
  ];

  let stratagyOptions = [
    { value: 0, text: `${t("pages.title.follow_message")}` },
    { value: 1, text: `${t("pages.title.message_only")}` },
  ];

  let requestOption = [
    "5",
    "10",
    "20",
    "30",
    "40",
    "50",
    t("pages.title.custom"),
  ];

  useEffect(() => {
    const count = customRequest ? customRequestCount : parseInt(norequest);
    const filteredOptions = filterIntervalOptions(count, media);
    setIntervalOptions(filteredOptions);

    if (count >20) {
  
      if(interval === "1-3" && media !== "Instagram") {
    
        setIntervalCount("3-5");
        const norequestValue =
          customRequest === true ? customRequestCount : norequest;
        handleChange(stratagy, customRequest, norequestValue,"3-5", search_index);
      }else if(interval === "2-4" && media === "Instagram"){
        setIntervalCount("4-6");

        const norequestValue =
          customRequest === true ? customRequestCount : norequest;
        handleChange(stratagy, customRequest, norequestValue,"4-6", search_index);
      }
    }
  }, [norequest, customRequestCount, customRequest, media]);

  const handleRequestChange = (value) => {
    setCustomRequest(t("pages.title.custom") === value ? true : false);
    setRequestCount(value === t("pages.title.custom") ? 1 : value);
    setCustomRequestCount(1);

    handleChange(
      stratagy,
      t("pages.title.custom") === value,
      value === t("pages.title.custom") ? 1 : value,
      interval,
      search_index
    );
  };

  const handleCustomRequest = (e) => {
    let value = e.target.value;
    if(parseInt(value) === 0){
      console.log(parseInt(value) === 0,"grp")
      e.preventDefault();
      return;
    }

    if (!isNaN(value)) {
      if (value > 50) {
        e.target.value = ""; 
        return;
      }
      value = Math.max(parseInt(value), 0);
      setCustomRequestCount(value);

      handleChange(stratagy, customRequest, value, interval, search_index);
    }
  };

  const handleInvtervalChange = (value) => {
    setIntervalCount(value);
    const norequestValue =
      customRequest === true ? customRequestCount : norequest;
    handleChange(stratagy, customRequest, norequestValue, value, search_index);
  };

  const handleLaunchChange = (value) => {
    setLaunchFrom(value);
    setSearchIndex(value === "no" ? 1 : search_index);
    const norequestValue =
      customRequest === true ? customRequestCount : norequest;
    handleChange(
      stratagy,
      customRequest,
      norequestValue,
      interval,
      value === "no" ? 1 : search_index
    );
  };

  const handleStratagyChange = (value) => {
    setStratagy(value);
    const norequestValue =
      customRequest === true ? customRequestCount : norequest;
    handleChange(value, customRequest, norequestValue, interval, search_index);
  };

  const handleCustomSearchIndexChange = (e) => {
    setSearchIndex(e.target.value);
    let value = e.target.value;

    if (!isNaN(value) && value <= 100000) {
      value = Math.max(parseInt(value), 0);
      setSearchIndex(value);
      const norequestValue =
        customRequest === true ? customRequestCount : norequest;
      handleChange(stratagy, customRequest, norequestValue, interval, value);
    } else {
      setSearchIndex(100000);
    }
  };

  const handleChange = (
    stratagy,
    customRequest,
    norequest,
    interval,
    search_index
  ) => {
    let stateChanges = {
      stratagy: stratagy,
      custom: customRequest,
      norequest: norequest,
      interval: interval,
    };

    if (media === "Facebook") {
      stateChanges.search_index = search_index;
    }
    onChange(stateChanges);
  };

  // useEffect(()=>{
  //   const count = customRequest ? customRequestCount : parseInt(norequest);

  // },[])

  return (
    <div className="col-1-3 request-main">
      <div className="col_1">
        <span className="title-18">{t("pages.title.how_many_requests")}</span>
        <div className="request-wraper">
          {requestOption.map((item, index) => {
           
            return (
              <label
                className="group-items"
                htmlFor={`request-${index}`}
                key={index}
              >
                <input
                  type="radio"
                  id={`request-${index}`}
                  name="request"
                  checked={
                    norequest === item || (item === t("pages.title.custom") && (customRequest === true || customRequestCount === '1'))
                
                  }
                  onClick={() => handleRequestChange(item)}
                />
                <div className="group-wraper-right">
                  <span className="group-name">{item}</span>
                </div>
              </label>
            );
          })}
        </div>
        <div className="request-wraper">
          {customRequest ? (
            <div className="col_1 keywords-wraper w-100">
              <span className="title-18"></span>
              <div style={{ display: "flex", gap: "20px" }}>
                <input
                  className="step-select"
                  type="number"
                  name="customRequestCount"
                  value={customRequestCount}
                  placeholder="min 1 and max 50"
                  onChange={handleCustomRequest}
                  style={{ width: "48%" }}
                />
                <span>{t("pages.title.min_1_max_50")}</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col_1">
        <span className="title-18">{t("pages.title.interval_")}</span>
        <div className="interval-wraper">
          {intervalOptions.map((item, index) => {
            return (
              <label
                className="group-items"
                htmlFor={`interval-${index}`}
                key={index}
              >
                <input
                  type="radio"
                  id={`interval-${index}`}
                  name="interval"
                  checked={item.value === interval ? true : false}
                  onChange={() => handleInvtervalChange(item.value)}
                />
                <div className="group-wraper-right">
                  <span className="group-name">
                    <span
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    ></span>
                  </span>
                </div>
              </label>
            );
          })}
        </div>
      </div>
      <div className="col_1">
        {media != "Facebook" && (
           <div className="col_1">
           <div className="col_1 ctm-col-wraper">
             <span className="title-18">{t("pages.title.strategy")}</span>
             <div className="lunch-wraper">
               {stratagyOptions.map((item, index) => {
                 return (
                   <label
                     className="group-items"
                     htmlFor={`stratagy-${index}`}
                     key={index}
                   >
                     <input
                       type="radio"
                       id={`stratagy-${index}`}
                       name="stratagy"
                       checked={item.value === stratagy ? true : false}
                       onChange={() => handleStratagyChange(item.value)}
                     />
                     <div className="group-wraper-right">
                       <span className="group-name">
                         <span
                           dangerouslySetInnerHTML={{ __html: item.text }}
                         ></span>
                       </span>
                     </div>
                   </label>
                 );
               })}
             </div>
           </div>
         </div>
        )}
      </div>
    </div>
  );
}
